import React, { ChangeEventHandler } from 'react';

interface ITextProps {
    name: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    value: string;
    error: string | null;
    readOnly?: boolean;
}

const TextInput: React.FC<ITextProps> = ({ name, onChange, value, error, readOnly = false }: ITextProps) => {
    let wrapperClass = 'form-group';
    if (error && error.length > 0) {
        wrapperClass += ' has-error';
    }

    return (
        <div className={wrapperClass}>
            <div className="field">
                <input type="text" name={name} className="form-control" value={value} onChange={onChange} readOnly={readOnly} />
                {error && <div className="alert alert-danger">{error}</div>}
            </div>
        </div>
    );
};

export default TextInput;
