import * as React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Container, Row, Col } from 'reactstrap';

export default class Layout extends React.Component {
    public render() {
        return (
            <Container fluid={true}>
                <Row>
                    <Col>
                        <Navbar bg="light" expand="lg">
                            <Navbar.Brand href="home">Statystyki pistoletowe</Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    <Nav.Link href="zawodyList">Zawody</Nav.Link>
                                    <Nav.Link href="wyszukaj">Wyszukaj</Nav.Link>
                                    <Nav.Link href="sredniaserii">Średnie</Nav.Link>
                                    {/* <Nav.Link href="porownaj">Porównaj</Nav.Link> */}
                                    <Nav.Link href="top10junior">Top 10 Junior</Nav.Link>
                                    <Nav.Link href="mvpjunml">MVP Junior Młodszy</Nav.Link>
                                    <Nav.Link href="liczby">Liczby</Nav.Link>
                                    <Nav.Link href="narzedzia">Narzędzia</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </Col>
                </Row>
                <Row>
                    <Col>{this.props.children}</Col>
                </Row>
            </Container>
        );
    }
}
