import React, { ChangeEventHandler, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SelectInput from '../shared/controls/SelectInput';
import { Option } from '../shared/controls/models/common';
import Loader from 'react-loader-spinner';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ou/OU.scss';
import IGetSredniaResponse from '../../api/zawody/model/GetSredniaResponse';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import { matchSorter } from 'match-sorter';
import { match } from 'react-router';
import IGetZawodnicyKlub from '../../api/zawody/model/GetZawodnicyKlub';

interface IDetailParams {
    id: string;
}

interface IDetailsProps {
    match?: match<IDetailParams>;
}

export interface IKlubData {
    konkurencjaId: string;
    kategoriaWiekowaId: string;
    plecId: string;
    krajId: string;
    rok: string;
    klub: string;
}

const Klub: React.FC<IDetailsProps> = ({ match }: IDetailsProps) => {
    const klubId = match?.params.id === undefined ? '' : match?.params.id;
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [sredniaData, setsredniaDataData] = useState<IKlubData>({
        konkurencjaId: '-1',
        kategoriaWiekowaId: '-1',
        plecId: '3',
        krajId: 'POL',
        rok: new Date().getFullYear().toString(),
        klub: klubId,
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [wynikiData, setwynikiData] = useState<IGetZawodnicyKlub[]>();

    // const onSelectChange = (value: string, name: string) => {
    //     setsredniaDataData(prevouData => ({
    //         ...prevouData,
    //         [name]: value,
    //     }));
    // };

    const getWyniki = () => {
        setLoaderVisible(true);
        statystykiClient()
            .getKlub(sredniaData)
            .then((result: IGetZawodnicyKlub[]) => {
                setwynikiData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        getWyniki();
    }, [sredniaData]);

    const loaderStyles = () => {
        return {
            opacity: loaderVisible ? '0.6' : '0',
            height: loaderVisible ? '100%' : '0',
            zIndex: 999999999,
        };
    };

    function userLink(userId: string) {
        return `/zawodnik/${userId}/`;
    }

    return (
        <>
            <div className="busy_loader" style={loaderStyles()}>
                <Loader type="Grid" visible={loaderVisible} />
            </div>
            <Container className="Panel">
                <Row>
                    <Col>
                        <ReactTable
                            columns={[
                                {
                                    Header: 'Id',
                                    id: 'zawodnik_id',
                                    accessor: d => d.zawodnik_id,
                                    show: false,
                                },
                                {
                                    Header: 'Zawodnik',
                                    accessor: 'nazwisko',
                                    Cell: ({ original }) => <Link to={userLink(original.zawodnik_id)}>{original.nazwisko}</Link>,
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { keys: ['nazwisko'] }),
                                    filterAll: true,
                                },
                                {
                                    Header: 'Imię',
                                    accessor: 'imie',
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { keys: ['imie'] }),
                                    filterAll: true,
                                },
                                {
                                    Header: 'Rocznik',
                                    accessor: 'rocznik',
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { threshold: matchSorter.rankings.WORD_STARTS_WITH, keys: ['rocznik'] }),
                                    filterAll: true,
                                },
                                {
                                    Header: 'Klub',
                                    accessor: 'klub',
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { keys: ['klub'] }),
                                    filterAll: true,
                                },
                            ]}
                            defaultSorted={[
                                {
                                    id: 'nazwisko',
                                    desc: false,
                                },
                            ]}
                            data={wynikiData}
                            loading={loaderVisible} // Display the loading overlay when we need it
                            showPageJump={false}
                            previousText="Poprzednia"
                            nextText="Następna"
                            loadingText="Ładowanie..."
                            noDataText="Brak danych"
                            pageText="Strona"
                            ofText="z"
                            rowsText="wierszy"
                            defaultPageSize={20}
                            className="-striped -highlight"
                            filterable={true}
                            resizable={true}
                        />
                    </Col>
                </Row>
            </Container>

            <ToastContainer />
        </>
    );
};

export default Klub;
