import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ou/OU.scss';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
import IGetBestOfTheYear from '../../api/zawody/model/GetBestOfTheYearResult';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import dateFormat from 'dateformat';

const Top10Junior: React.FC = () => {
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [wynikiData, setwynikiData] = useState<IGetBestOfTheYear[]>();
    const [wynikiData1, setwynikiData1] = useState<IGetBestOfTheYear[]>();

    const getWyniki = () => {
        setLoaderVisible(true);
        statystykiClient()
            .getBestResultsOfYearJunior()
            .then((result: IGetBestOfTheYear[]) => {
                setwynikiData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };
    const getWynikijunml = () => {
        setLoaderVisible(true);
        statystykiClient()
            .getBestResultsOfYearLJunior()
            .then((result: IGetBestOfTheYear[]) => {
                setwynikiData1(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        getWyniki();
        getWynikijunml();
    }, []);

    function zawodyLink(zawodyId: string) {
        return `/zawody/${zawodyId}/`;
    }
    function userLink(userId: string) {
        return `/zawodnik/${userId}/`;
    }

    const renderLista = () => {
        if (typeof wynikiData !== 'undefined') {
            const group1 = wynikiData.reduce((r: any, a: IGetBestOfTheYear) => {
                r[a.konkurencja] = [...(r[a.konkurencja] || []), a];
                return r;
            }, []);

            return Object.keys(group1).map(key => {
                return (
                    <Col key={key}>
                        <div className="konkurencjaDiv">{key}</div>
                        <Table size="sm" striped bordered hover className="zawodyWynikiTable">
                            <thead>
                                <tr>
                                    <th>Wynik</th>
                                    <th>Nazwisko</th>
                                    <th>Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                {group1[key].map((item: IGetBestOfTheYear, index: any) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{item.suma}</td>

                                            <td className="text-center">
                                                <Link to={userLink(item.zawodnik_id)}>{item.nazwisko}</Link>
                                            </td>

                                            <td className="text-center">
                                                <Link to={zawodyLink(item.zawody_id)}>{dateFormat(item.data, 'dd-mm-yyyy')}</Link>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                );
            });
        }
    };
    const renderLista1 = () => {
        if (typeof wynikiData1 !== 'undefined') {
            const group1 = wynikiData1.reduce((r: any, a: IGetBestOfTheYear) => {
                r[a.konkurencja] = [...(r[a.konkurencja] || []), a];
                return r;
            }, []);

            return Object.keys(group1).map(key => {
                return (
                    <Col key={key}>
                        <div className="konkurencjaDiv">{key}</div>
                        <Table size="sm" striped bordered hover className="zawodyWynikiTable">
                            <thead>
                                <tr>
                                    <th>Wynik</th>
                                    <th>Nazwisko</th>
                                    <th>Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                {group1[key].map((item: IGetBestOfTheYear, index: any) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{item.suma}</td>

                                            <td className="text-center">
                                                <Link to={userLink(item.zawodnik_id)}>{item.nazwisko}</Link>
                                            </td>

                                            <td className="text-center">
                                                <Link to={zawodyLink(item.zawody_id)}>{dateFormat(item.data, 'dd-mm-yyyy')}</Link>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                );
            });
        }
    };

    return (
        <>
            <Container className="Panel">
                <div className="NazwaZawodow">Top 10 wyników z tego roku w kategorii junior </div>
                <Row xs={3} sm={3} md={3} lg={3}>
                    {renderLista()}
                </Row>
            </Container>
            <Container className="Panel">
                <div className="NazwaZawodow">Top 10 wyników z tego roku w kategorii junior młodszy</div>
                <Row xs={3} sm={3} md={3} lg={3}>
                    {renderLista1()}
                </Row>
            </Container>
            <ToastContainer />
        </>
    );
};

export default Top10Junior;
