import React, { ChangeEventHandler, useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Loader from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Wyszukaj: React.FC = () => {
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {}, []);

    const loaderStyles = () => {
        return {
            opacity: loaderVisible ? '0.6' : '0',
            height: loaderVisible ? '100%' : '0',
            zIndex: 999999999,
        };
    };

    // return current date in format YYYY-MM-DD HH:MM:SS
    const getCurrentDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        let month: string | number = date.getMonth() + 1;
        let day: string | number = date.getDate();
        let hours: string | number = date.getHours();
        let minutes: string | number = date.getMinutes();
        let seconds: string | number = date.getSeconds();

        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        if (hours < 10) hours = '0' + hours;
        if (minutes < 10) minutes = '0' + minutes;
        if (seconds < 10) seconds = '0' + seconds;

        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    };

    return (
        <>
            <div className="busy_loader" style={loaderStyles()}>
                <Loader type="Grid" visible={loaderVisible} />
            </div>
            <Container className="Panel">Wyszukaj</Container>

            <ToastContainer />
        </>
    );
};

export default Wyszukaj;
