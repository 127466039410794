import React, { ChangeEventHandler, useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from 'react-loader-spinner';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ou/OU.scss';
import { Table } from 'react-bootstrap';
import { match } from 'react-router';
import IZawodnikModel from '../../api/zawody/model/ZawodnikModel';
import { Link } from 'react-router-dom';
import IWynikModel from '../../api/zawody/model/WynikModel';
import SrednieByZawodnikModel from '../../api/zawody/model/SrednieByZawodnikModel';
import { Axis, Chart, Legend, Line, LineAdvance, Point } from 'bizcharts';
import NajlepszeByZawodnikModel from '../../api/zawody/model/NajlepszeByZawodnikModel';
import { GALLERY_CONSTS, ProGallery, Container as ImageContainer } from 'pro-gallery';
import 'pro-gallery/dist/statics/main.css';
import StatGallery from '../shared/Gallery';
import Accordion from 'react-bootstrap/Accordion';
import IZawodnikImageModel from '../../api/zawody/model/ImagesModel';
import dateFormat from 'dateformat';
import { parse, isAfter, isBefore } from 'date-fns';

import DatePicker from './../shared/controls/DatePicker';
interface IDetailParams {
    id: string;
}

interface IDetailsProps {
    match?: match<IDetailParams>;
}
interface IImagesContainer {
    width: number;
    height: number;
}

export interface ISredniaByZawodnikData {
    zawodnikId: string;
    startDate: string;
    endDate: string;
}

export interface IWynikiByRokData {
    rok: string;
}

const GALLERY_EVENTS = GALLERY_CONSTS.events;

const Zawodnik: React.FC<IDetailsProps> = ({ match }: IDetailsProps) => {
    const zawodnikId = match?.params.id === undefined ? 0 : Number(match?.params.id);

    const startdate = new Date(new Date().getFullYear(), 0, 1, 1);
    const enddate = new Date(new Date().getFullYear(), 11, 31);
    //startdate.setFullYear(enddate.getFullYear() - 1);

    const [loaderVisible, setLoaderVisible] = useState(false);

    const [zawodnikData, setZawodnikData] = useState<IZawodnikModel>();
    const [zawodnikImagesData, setZawodnikImagesData] = useState<IZawodnikImageModel[]>();
    const [srednieWynikowData, setSrednieWynikowData] = useState<SrednieByZawodnikModel[]>();
    const [najlepszeWynikiData, setNajlepszeWynikiData] = useState<NajlepszeByZawodnikModel[]>();
    const [sredniaData, setSredniaData] = useState<ISredniaByZawodnikData>({
        zawodnikId: zawodnikId.toString(),
        startDate: startdate.toISOString().substring(0, 10),
        endDate: enddate.toISOString().substring(0, 10),
    });

    const getZawodnik = () => {
        setLoaderVisible(true);
        const id = match?.params.id === undefined ? 0 : Number(match?.params.id);
        statystykiClient()
            .getZawodnik(id)
            .then((result: IZawodnikModel) => {
                if (result == null) {
                    toast.error('Nie znaleziono zawodnika', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else setZawodnikData(result);

                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };
    const getImages = () => {
        setLoaderVisible(true);
        const id = match?.params.id === undefined ? 0 : Number(match?.params.id);
        statystykiClient()
            .getImagesByZawodnik(id)
            .then((result: IZawodnikImageModel[]) => {
                if (result == null) {
                    toast.error('Nie znaleziono zawodnika', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else setZawodnikImagesData(result);

                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    const getSrednie = () => {
        setLoaderVisible(true);
        const id = match?.params.id === undefined ? 0 : Number(match?.params.id);
        statystykiClient()
            .getSredniaByZawodnik(sredniaData)
            .then((result: SrednieByZawodnikModel[]) => {
                if (result == null) {
                    toast.error('Nie znaleziono srednich', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else setSrednieWynikowData(result);

                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    const getNajlepsze = () => {
        setLoaderVisible(true);
        const id = match?.params.id === undefined ? 0 : Number(match?.params.id);
        statystykiClient()
            .getNajlepszeByZawodnik(sredniaData)
            .then((result: NajlepszeByZawodnikModel[]) => {
                if (result == null) {
                    toast.error('Nie znaleziono srednich', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else setNajlepszeWynikiData(result);

                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        getZawodnik();
        getSrednie();
        getNajlepsze();
        getImages();
    }, []);

    useEffect(() => {
        //getZawodnik();
        getSrednie();
        getNajlepsze();
        getImagesArray();
    }, [sredniaData]);

    const loaderStyles = () => {
        return {
            opacity: loaderVisible ? '0.6' : '0',
            height: loaderVisible ? '100%' : '0',
            zIndex: 999999999,
        };
    };

    function zawodyLink(zawodyId: string) {
        return `/zawody/${zawodyId}/`;
    }
    const onSelectChange = (value: string, name: string) => {
        setSredniaData(prevouData => ({
            ...prevouData,
            [name]: value,
        }));
    };

    const renderSrednie = () => {
        if (typeof srednieWynikowData !== 'undefined') {
            return (
                <div>
                    <Table size="sm" striped bordered hover className="zawodyWynikiTable">
                        <thead>
                            <tr>
                                <th>Konkurencja</th>
                                <th>Srednia seria</th>
                                <th>Suma</th>
                                <th>Ilosc</th>
                            </tr>
                        </thead>
                        <tbody>
                            {srednieWynikowData.map((item: SrednieByZawodnikModel, index: any) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.nazwa}</td>
                                        <td>{item.srednia}</td>
                                        <td>{item.suma}</td>
                                        <td>{item.ilosc}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            );
        }
    };

    const getImagesArray = () => {
        if (typeof zawodnikImagesData !== 'undefined') {
            const newItems = zawodnikImagesData.map((item: IZawodnikImageModel, index: any) => ({
                itemId: index += '',
                mediaUrl: 'https://wyniki.t-it.pl/static/images/' + item.path,
                metaData: {
                    type: 'image',
                    // height: 1920,
                    // width: 1080,
                },
            }));
            // console.log(newItems);
            // console.log(items);
            return newItems;
        } else return [];
    };
    // const renderImages = () => {
    //     if (typeof zawodnikImagesData !== 'undefined') {
    //         return (
    //             <div>
    //                 <Table size="sm" striped bordered hover className="zawodyWynikiTable">
    //                     <thead>
    //                         <tr>
    //                             <th>path</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {zawodnikImagesData.map((item: IZawodnikImageModel, index: any) => {
    //                             return (
    //                                 <tr key={index}>
    //                                     <td>{item.path}</td>
    //                                 </tr>
    //                             );
    //                         })}
    //                     </tbody>
    //                 </Table>
    //             </div>
    //         );
    //     }
    // };
    const isNullorWhiteSpace = (value: string): boolean => {
        if (typeof value != 'undefined' && value) return false;
        else return true;
    };

    const renderBest = () => {
        if (typeof najlepszeWynikiData !== 'undefined') {
            return (
                <div>
                    Najlepsze wyniki
                    <Table size="sm" striped bordered hover className="zawodyWynikiTable bestTable">
                        <thead>
                            <tr>
                                <th>Konk.</th>
                                <th>Wynik</th>
                                <th>Zawody</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {najlepszeWynikiData.map((item: NajlepszeByZawodnikModel, index: any) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.konkurencjaNazwa}</td>
                                        <td>{item.wynik}</td>
                                        <td>
                                            <span className="bestTableZawodySpan">{item.zawody}</span>
                                        </td>
                                        <td>
                                            <span className="bestTableDataSpan">{dateFormat(item.data, 'dd-mm-yyyy')}</span>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            );
        }
    };

    const sumProp = (arr: any[], prop: string) => {
        return arr.reduce((a, b) => a + (b[prop] || 0), 0);
    };

    const dajWykresDane = (dane: IWynikModel[]) => {
        // a = [{ a: 1 }, { b: 2 }];
        // const b = dane.map(function(x: IWynikModel) {
        //     return { seria1: x.seria1, seria2: x.seria2, seria3: x.seria3, seria4: x.seria4, seria5: x.seria5, seria6: x.seria6, suma: x.suma, data: x.zawody.data.substring(0, 10), miejsce: x.miejsce };
        // });
        if (dane.length == 1) return <></>;
        const b = dane.map(function(x: IWynikModel) {
            let iloscSerii = 0;

            if (!isNullorWhiteSpace(x.seria1)) iloscSerii++;
            if (!isNullorWhiteSpace(x.seria2)) iloscSerii++;
            if (!isNullorWhiteSpace(x.seria3)) iloscSerii++;
            if (!isNullorWhiteSpace(x.seria4)) iloscSerii++;
            if (!isNullorWhiteSpace(x.seria5)) iloscSerii++;
            if (!isNullorWhiteSpace(x.seria6)) iloscSerii++;
            const srednia = Number(x.suma) / iloscSerii;

            return { srednia: srednia, data: x.zawody.data.substring(0, 10), miejsce: x.miejsce };
        });

        const dupalek: any[] = [];

        dupalek.push({ seria: sumProp(dane, 'seria1') / dane.length, pozycja: 1, wynik: 'all' });
        dupalek.push({ seria: sumProp(dane, 'seria2') / dane.length, pozycja: 2, wynik: 'all' });
        dupalek.push({ seria: sumProp(dane, 'seria3') / dane.length, pozycja: 3, wynik: 'all' });
        dupalek.push({ seria: sumProp(dane, 'seria4') / dane.length, pozycja: 4, wynik: 'all' });
        dupalek.push({ seria: sumProp(dane, 'seria5') / dane.length, pozycja: 5, wynik: 'all' });
        dupalek.push({ seria: sumProp(dane, 'seria6') / dane.length, pozycja: 6, wynik: 'all' });

        dane.forEach((item, index, arr) => {
            dupalek.push({ seria: item.seria1, pozycja: 1, wynik: item.suma });
            dupalek.push({ seria: item.seria2, pozycja: 2, wynik: item.suma });
            dupalek.push({ seria: item.seria3, pozycja: 3, wynik: item.suma });
            dupalek.push({ seria: item.seria4, pozycja: 4, wynik: item.suma });
            dupalek.push({ seria: item.seria5, pozycja: 5, wynik: item.suma });
            dupalek.push({ seria: item.seria6, pozycja: 6, wynik: item.suma });
        });

        // const result = dane.reduce(function(accumulator, currVal) {
        //     accumulator.seria;
        //     return { seria: item.seria6, pozycja: 6, wynik: item.suma };
        //     currVal.forEach(function(b, i) {
        //         accumulator[i] = (accumulator[i] || 0) + b;
        //     });
        //     return accumulator;
        // }, []);

        //console.log(dupalek);

        const scale = {
            seria: {
                min: 90, // 定义数值范围的最小值
                max: 100, // 定义数值范围的最大值
                // ticks: [100, 1000, 2000, 3000], // 用于指定坐标轴上刻度点的文本信息，当用户设置了 ticks 就会按照 ticks 的个数和文本来显示。
                tickInterval: 1, // 用于指定坐标轴各个标度点的间距，是原始数据之间的间距差值，tickCount 和 tickInterval 不可以同时声明。
                tickCount: 10, // 定义坐标轴刻度线的条数，默认为 5
            },
        };

        return (
            <>
                <Row>
                    <Col>Wykres średnich wyników</Col>
                </Row>
                <Chart padding={[10, 20, 50, 40]} autoFit height={300} data={b}>
                    <Line shape="smooth" position="data*srednia" color="l (270) 0:rgba(255, 146, 255, 1) .5:rgba(100, 268, 255, 1) 1:rgba(215, 0, 255, 1)" />
                    {/* <Line shape="smooth" point position="data*miejsce" color="miejsce" /> */}
                </Chart>
                {/* <Row>
                    <Col>Wykres serii</Col>
                </Row> */}

                {/* <Chart padding={[10, 20, 50, 40]} autoFit height={300} data={dupalek}>
                    <Line
                        shape="smooth"
                        position="pozycja*seria"
                        color={[
                            'wynik',
                            xVal => {
                                if (xVal === 'all') {
                                    return 'red';
                                }
                                return 'blue';
                            },
                        ]}
                    />
                </Chart> */}
            </>
        );
    };
    function compare(a: IWynikModel, b: IWynikModel) {
        if (a.zawody.data < b.zawody.data) {
            return -1;
        }
        if (a.zawody.data > b.zawody.data) {
            return 1;
        }
        return 0;
    }

    const renderWynikiValue = (dane: IWynikModel[]) => {
        return dane.sort(compare).map((item: IWynikModel, index: any) => {
            return (
                <tr key={index}>
                    <td className="text-center">{item.miejsce}</td>

                    <td className="text-center">{item.seria1}</td>
                    <td className="text-center">{item.seria2}</td>
                    <td className="text-center">{item.seria3}</td>
                    <td className="text-center">{item.seria4}</td>
                    <td className="text-center">{item.seria5}</td>
                    <td className="text-center">{item.seria6}</td>
                    <td className="text-center">{+item.seria1 + +item.seria2 + +item.seria3 + +item.seria4 + +item.seria5 + +item.seria6}</td>
                    <td className="text-center">{item.centralne}</td>
                    <td>
                        <span className="TableZawodySpan">
                            <Link to={zawodyLink(item.zawody.id)}>{item.zawody.nazwa}</Link>
                        </span>
                    </td>
                    <td>
                        <span className="TableDataSpan">{dateFormat(item.zawody.data, 'dd-mm-yyyy')}</span>
                    </td>
                </tr>
            );
        });
    };
    const filterDate = (wynik: IWynikModel) => {
        const start = parse(sredniaData.startDate, 'yyyy-MM-dd', new Date());
        const end = parse(sredniaData.endDate, 'yyyy-MM-dd', new Date());
        const zawodyDataString = wynik.zawody.data.substring(0, 10);
        const zawody = parse(zawodyDataString, 'yyyy-MM-dd', new Date());

        return isAfter(zawody, start) && isBefore(zawody, end);
    };
    const renderLista = () => {
        if (typeof zawodnikData?.wyniki !== 'undefined') {
            const year = zawodnikData.wyniki?.filter(filterDate);
            // const group1 = year?.reduce((r: any, a: IWynikModel) => {
            //     r[a.konkurencja.nazwa + ' ' + a.kategoriaWiekowa.nazwa + ' ' + a.plec.nazwa] = [...(r[a.konkurencja.nazwa + ' ' + a.kategoriaWiekowa.nazwa + ' ' + a.plec.nazwa] || []), a];
            //     return r;
            // }, []);
            const group1 = year?.reduce((r: any, a: IWynikModel) => {
                r[a.konkurencja.nazwa] = [...(r[a.konkurencja.nazwa] || []), a];
                return r;
            }, []);

            return Object.keys(group1).map(key => {
                return (
                    <Accordion.Item key={key} eventKey={key}>
                        <Accordion.Header>{key}</Accordion.Header>
                        <Accordion.Body>
                            <div className="konkurencjaDiv">{key}</div>
                            <Table size="sm" striped bordered hover className="zawodyWynikiTable">
                                <thead>
                                    <tr>
                                        <th className="zawodyWynikiTableMiejsce">M-ce</th>

                                        <th className="zawodyWynikiTableseria">1</th>
                                        <th className="zawodyWynikiTableseria">2</th>
                                        <th className="zawodyWynikiTableseria">3</th>
                                        <th className="zawodyWynikiTableseria">4</th>
                                        <th className="zawodyWynikiTableseria">5</th>
                                        <th className="zawodyWynikiTableseria">6</th>
                                        <th className="zawodyWynikiTableseria">Suma</th>
                                        <th className="zawodyWynikiTableseria">10x</th>
                                        <th>Zawody</th>
                                        <th>Data</th>
                                    </tr>
                                </thead>
                                <tbody>{renderWynikiValue(group1[key])}</tbody>
                            </Table>
                            {dajWykresDane(group1[key])}
                        </Accordion.Body>
                    </Accordion.Item>
                );
            });
        }
    };

    //galeria ###################################################

    // The size of the gallery container. The images will fit themselves in it
    const ref = useRef<HTMLHeadingElement>(null);
    const [imagesWidth, setimagesWidth] = useState<ImageContainer>({ width: 1100, height: 500 });

    useEffect(() => {
        setimagesWidth({ width: ref.current ? ref.current.offsetWidth - 30 : 0, height: 400 });
    }, []);

    useLayoutEffect(() => {
        function handleResize() {
            setimagesWidth({ width: ref.current ? ref.current.offsetWidth - 30 : 0, height: 400 });
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    // The eventsListener will notify you anytime something has happened in the gallery.
    // const eventsListener = (eventName: any, eventData: any) => {
    //     switch (eventName) {
    //         case GALLERY_EVENTS.ITEM_ACTION_TRIGGERED:
    //             this.setState({ fullscreenIdx: eventData.idx });
    //             break;
    //         default:
    //             console.log({ eventName, eventData });
    //             break;
    //     }
    // };

    // The scrollingElement is usually the window, if you are scrolling inside another element, suplly it here
    // const scrollingElement = window;

    //galeria ###################################################

    return (
        <>
            <div className="busy_loader" style={loaderStyles()}>
                <Loader type="Grid" visible={loaderVisible} />
            </div>
            <Container className="Panel">
                <div className="NazwaZawodow">
                    {zawodnikData?.imie} {zawodnikData?.nazwisko}
                </div>
                <div className="MiejsceZawodow">Klub: {zawodnikData?.klub} </div>
                <div className="DataZawodow">Rocznik: {zawodnikData?.rocznik}</div>

                <Row>
                    {/* <Col ref={ref}>{zawodnikId == 52 && <ProGallery items={items} options={options} container={imagesWidth} />} </Col> */}
                    <Col ref={ref}>{getImagesArray().length > 0 && <StatGallery items={getImagesArray()} container={imagesWidth} />} </Col>
                    {/* <Col ref={ref}>{zawodnikId == 52 && <StatGallery items={items} container={imagesWidth} />} </Col> */}
                </Row>
                <Row>
                    <Col>
                        <fieldset className="listAdder">
                            <legend className="listAdder">Filtruj</legend>
                            <Row>
                                <Col className="">
                                    <DatePicker textAbove="Data od" name="startDate" value={sredniaData.startDate} onChange={onSelectChange} error={''} />
                                </Col>
                                <Col className="">
                                    <DatePicker textAbove="Data do" name="endDate" value={sredniaData.endDate} onChange={onSelectChange} error={''} />
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                </Row>
                <Row>
                    <Col>{renderSrednie()}</Col>
                </Row>
                <Row>
                    <Col>{renderBest()}</Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Accordion defaultActiveKey="0">{renderLista()}</Accordion>
                    </Col>
                </Row>
            </Container>

            <ToastContainer />
        </>
    );
};

export default Zawodnik;
