import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ou/OU.scss';

const Narzedzia: React.FC = () => {
    return (
        <>
            <Container className="Panel">
                <Row>
                    <Col>Narzędzia do użytku w treningu</Col>
                </Row>
            </Container>
            <Container className="Panel">
                <Row>
                    <Col></Col>
                    <Col>
                        <fieldset className="listAdder">
                            <legend className="listAdder">Narzędzia</legend>
                            <div>
                                <a href="http://strzelanko.t-it.pl/" target="_blank">
                                    Czasoklikacz
                                </a>
                            </div>
                            <div>
                                <a href="http://strzelanko.t-it.pl/potwierdzacz.html" target="_blank">
                                    Potwierdzacz
                                </a>
                            </div>
                            <div>
                                <a href="http://strzelanko.t-it.pl/wysokosciolicz.html" target="_blank">
                                    Kalkulator wysokości zawieszenia pomniejszonej tarczy
                                </a>
                            </div>
                        </fieldset>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
            </Container>
            <ToastContainer />
        </>
    );
};

export default Narzedzia;
