import React, { ChangeEventHandler, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SelectInput from './../shared/controls/SelectInput';
import { Option } from './../shared/controls/models/common';
import Loader from 'react-loader-spinner';
import { statystykiClient } from '../../api/zawody/ZawodyClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../ou/OU.scss';
import IGetSredniaResponse from '../../api/zawody/model/GetSredniaResponse';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import { matchSorter } from 'match-sorter';

//interface ISredniaProps {}

export interface ISredniaData {
    konkurencjaId: string;
    kategoriaWiekowaId: string;
    plecId: string;
    krajId: string;
    rok: string;
    wojewodztwoId: string;
}

const SredniaSerii: React.FC = () => {
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [sredniaData, setsredniaDataData] = useState<ISredniaData>({
        konkurencjaId: '1',
        kategoriaWiekowaId: '-1',
        plecId: '3',
        krajId: 'POL',
        rok: new Date().getFullYear().toString(),
        wojewodztwoId: '-1',
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [wynikiData, setwynikiData] = useState<IGetSredniaResponse[]>();

    const [selectKonkurencje, setSelectKonkurencje] = useState<Option[]>([
        { Label: 'PPN', Value: '1', AditionalData: '' },
        { Label: 'PDW', Value: '2', AditionalData: '' },
        { Label: 'PSZ', Value: '3', AditionalData: '' },
        { Label: 'PSP', Value: '4', AditionalData: '' },
        { Label: 'PST', Value: '5', AditionalData: '' },
        { Label: 'PCZ', Value: '6', AditionalData: '' },
        { Label: 'PPN MIX', Value: '7', AditionalData: '' },
    ]);
    const [selectKategorieWiekowe, setSelectKategorieWiekowe] = useState<Option[]>([
        { Label: '', Value: '-1', AditionalData: '' },
        { Label: 'Senior/Seniorka', Value: '1', AditionalData: '' },
        { Label: 'Junior/Juniorka', Value: '2', AditionalData: '' },
        { Label: 'Junior/Juniorka mł.', Value: '3', AditionalData: '' },
        { Label: 'Młodzik/Młodziczka', Value: '4', AditionalData: '' },
    ]);
    const [selectPlec, setSelectPlec] = useState<Option[]>([
        { Label: 'Open', Value: '3', AditionalData: '' },
        { Label: 'Kobiet', Value: '1', AditionalData: '' },
        { Label: 'Mężczyzn', Value: '2', AditionalData: '' },
    ]);
    const [selectKraj, setSelectKraj] = useState<Option[]>([
        { Label: 'Polska', Value: 'POL', AditionalData: '' },
        { Label: 'Wszyscy', Value: 'ALL', AditionalData: '' },
    ]);
    const [selectRok, setSelectRok] = useState<Option[]>([
        { Label: 'Wszystko', Value: '-1', AditionalData: '' },
        { Label: '2024', Value: '2024', AditionalData: '' },
        { Label: '2023', Value: '2023', AditionalData: '' },
        { Label: '2022', Value: '2022', AditionalData: '' },
        { Label: '2021', Value: '2021', AditionalData: '' },
        { Label: '2020', Value: '2020', AditionalData: '' },
        { Label: '2019', Value: '2019', AditionalData: '' },
    ]);
    const [selectWojewodztwo, setSelectWojewodztwo] = useState<Option[]>([
        { Label: 'Wszystko', Value: '-1', AditionalData: '' },
        { Label: 'Dolnośląskie', Value: '1', AditionalData: '' },
        { Label: 'Kujawsko-pomorskie', Value: '2', AditionalData: '' },
        { Label: 'Lubelskie', Value: '3', AditionalData: '' },
        { Label: 'Lubuskie', Value: '4', AditionalData: '' },
        { Label: 'Łódzkie', Value: '5', AditionalData: '' },
        { Label: 'Małopolskie', Value: '6', AditionalData: '' },
        { Label: 'Mazowieckie', Value: '7', AditionalData: '' },
        { Label: 'Opolskie', Value: '8', AditionalData: '' },
        { Label: 'Podkarpackie', Value: '9', AditionalData: '' },
        { Label: 'Podlaskie', Value: '10', AditionalData: '' },
        { Label: 'Pomorskie', Value: '11', AditionalData: '' },
        { Label: 'Śląskie', Value: '12', AditionalData: '' },
        { Label: 'Świętokrzyskie', Value: '13', AditionalData: '' },
        { Label: 'Warmińsko-mazurskie', Value: '14', AditionalData: '' },
        { Label: 'Wielkopolskie', Value: '15', AditionalData: '' },
        { Label: 'Zachodniopomorskie', Value: '16', AditionalData: '' },
    ]);

    const onSelectChange = (value: string, name: string) => {
        setsredniaDataData(prevouData => ({
            ...prevouData,
            [name]: value,
        }));
    };

    const getWyniki = () => {
        setLoaderVisible(true);
        statystykiClient()
            .getSredniaSeriaByKonkurencja(sredniaData)
            .then((result: IGetSredniaResponse[]) => {
                setwynikiData(result);
                setLoaderVisible(false);
            })
            .catch(function(error) {
                console.log(error);
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        getWyniki();
    }, [sredniaData]);

    const loaderStyles = () => {
        return {
            opacity: loaderVisible ? '0.6' : '0',
            height: loaderVisible ? '100%' : '0',
            zIndex: 999999999,
        };
    };

    function userLink(userId: string) {
        return `/zawodnik/${userId}/`;
    }

    function klubLink(klubId: string) {
        return `/klub/${klubId}/`;
    }
    const renderLista = () => {
        if (typeof wynikiData !== 'undefined') {
            return (
                <div>
                    <Table size="sm" striped bordered hover className="zawodyWynikiTable">
                        <thead>
                            <tr>
                                <th className="zawodyWynikiTableMiejsce">Lp</th>
                                <th className="zawodyWynikiTableMiejsce">Średnia</th>
                                <th className="zawodyWynikiTableImie">Ilość</th>
                                <th className="zawodyWynikiTableNazwisko">Suma</th>
                                <th className="zawodyWynikiTableseria">Nazwisko</th>
                                <th className="zawodyWynikiTableseria">Imie</th>
                                <th className="zawodyWynikiTableseria">Rocznik</th>
                                <th className="zawodyWynikiTableseria">Klub</th>
                            </tr>
                        </thead>
                        <tbody>
                            {wynikiData
                                .sort((a, b) => b.srednia - a.srednia)
                                .map((item: IGetSredniaResponse, index: any) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.srednia}</td>
                                            <td>{item.ilosc}</td>
                                            <td>{item.suma}</td>
                                            <td>
                                                <Link to={userLink(item.zawodnik_id)}>{item.nazwisko}</Link>
                                            </td>
                                            <td>{item.imie}</td>
                                            <td>{item.rocznik}</td>
                                            <td>{item.klub}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                </div>
            );
        }
    };

    return (
        <>
            <div className="busy_loader" style={loaderStyles()}>
                <Loader type="Grid" visible={loaderVisible} />
            </div>
            <Container className="Panel">
                <Row>
                    <Col>
                        <fieldset className="listAdder">
                            <legend className="listAdder">Filtruj</legend>
                            <Row>
                                <Col className="blue">Rok</Col>
                                <Col className="light-blue">
                                    <SelectInput name="rok" value={sredniaData.rok} onChange={onSelectChange} error={errors['rok']} options={selectRok} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="blue">Konkurencja</Col>
                                <Col className="light-blue">
                                    <SelectInput name="konkurencjaId" value={sredniaData.konkurencjaId} onChange={onSelectChange} error={errors['konkurencjaId']} options={selectKonkurencje} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="blue">Kraj</Col>
                                <Col className="light-blue">
                                    <SelectInput name="krajId" value={sredniaData.krajId} onChange={onSelectChange} error={errors['krajId']} options={selectKraj} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="blue">Województwo</Col>
                                <Col className="light-blue">
                                    <SelectInput name="wojewodztwoId" value={sredniaData.wojewodztwoId} onChange={onSelectChange} error={errors['wojewodztwoId']} options={selectWojewodztwo} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="blue">Rodzaj</Col>
                                <Col className="light-blue">
                                    <SelectInput name="plecId" value={sredniaData.plecId} onChange={onSelectChange} error={errors['plecId']} options={selectPlec} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="blue">Kategoria wiekowa</Col>
                                <Col className="light-blue">
                                    <SelectInput name="kategoriaWiekowaId" value={sredniaData.kategoriaWiekowaId} onChange={onSelectChange} error={errors['kategoriaWiekowaId']} options={selectKategorieWiekowe} />
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                </Row>
                {/* <Row>
                    <Col>{renderLista()}</Col>
                </Row> */}
                <Row>
                    <Col>
                        <ReactTable
                            columns={[
                                {
                                    Header: 'Id',
                                    id: 'zawodnik_id',
                                    accessor: d => d.zawodnik_id,
                                    show: false,
                                },
                                {
                                    Header: 'Pozycja',
                                    accessor: 'no',
                                    sortMethod: (a, b) => {
                                        const a1: number = +a;
                                        const b1: number = +b;

                                        return a1 > b1 ? 1 : -1;
                                    },
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { threshold: matchSorter.rankings.WORD_STARTS_WITH, keys: ['no'] }),
                                    filterAll: true,
                                },
                                {
                                    Header: 'Średnia',
                                    accessor: 'srednia',
                                    sortMethod: (a, b) => {
                                        const a1: number = +a;
                                        const b1: number = +b;

                                        return a1 > b1 ? 1 : -1;
                                    },
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { threshold: matchSorter.rankings.WORD_STARTS_WITH, keys: ['srednia'] }),
                                    filterAll: true,
                                },
                                {
                                    Header: 'Ilość serii',
                                    accessor: 'ilosc',
                                    sortMethod: (a, b) => {
                                        const a1: number = +a;
                                        const b1: number = +b;

                                        return a1 > b1 ? 1 : -1;
                                    },
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { threshold: matchSorter.rankings.WORD_STARTS_WITH, keys: ['ilosc'] }),
                                    filterAll: true,
                                },
                                {
                                    Header: 'Suma',
                                    accessor: 'suma',
                                    sortMethod: (a, b) => {
                                        const a1: number = +a;
                                        const b1: number = +b;

                                        return a1 > b1 ? 1 : -1;
                                    },
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { threshold: matchSorter.rankings.WORD_STARTS_WITH, keys: ['suma'] }),
                                    filterAll: true,
                                },
                                {
                                    Header: 'Zawodnik',
                                    accessor: 'nazwisko',
                                    Cell: ({ original }) => <Link to={userLink(original.zawodnik_id)}>{original.nazwisko}</Link>,
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { keys: ['nazwisko'] }),
                                    filterAll: true,
                                },
                                {
                                    Header: 'Imię',
                                    accessor: 'imie',
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { keys: ['imie'] }),
                                    filterAll: true,
                                },
                                {
                                    Header: 'Rocznik',
                                    accessor: 'rocznik',
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { threshold: matchSorter.rankings.WORD_STARTS_WITH, keys: ['rocznik'] }),
                                    filterAll: true,
                                },
                                {
                                    Header: 'Klub',
                                    accessor: 'klub',
                                    Cell: ({ original }) => <Link to={klubLink(original.klub)}>{original.klub}</Link>,
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { keys: ['klub'] }),
                                    filterAll: true,
                                },
                                {
                                    Header: 'Województwo',
                                    accessor: 'wojewodztwo',
                                    filterMethod: (filter: any, rows: any) => matchSorter(rows, filter.value, { threshold: matchSorter.rankings.WORD_STARTS_WITH, keys: ['wojewodztwo'] }),
                                    filterAll: true,
                                },
                            ]}
                            defaultSorted={[
                                {
                                    id: 'srednia',
                                    desc: true,
                                },
                            ]}
                            data={wynikiData}
                            loading={loaderVisible} // Display the loading overlay when we need it
                            showPageJump={false}
                            previousText="Poprzednia"
                            nextText="Następna"
                            loadingText="Ładowanie..."
                            noDataText="Brak danych"
                            pageText="Strona"
                            ofText="z"
                            rowsText="wierszy"
                            defaultPageSize={100}
                            className="-striped -highlight"
                            filterable={true}
                            resizable={true}
                        />
                    </Col>
                </Row>
            </Container>

            <ToastContainer />
        </>
    );
};

export default SredniaSerii;
